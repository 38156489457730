import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './assets/Fonts/Presario-text/Presario-Text.ttf'


ReactDOM.render(<App />,
  document.getElementById('root')
);

