import React from 'react'
import {Auth, User} from './routes/routes'

function App() {
  return (
    <>
    <Auth/>
    <User/>
    </>
  );
}

export default App;
